<template>
  <a
    href="https://api.whatsapp.com/send?phone=5515998341602"
    v-if="$route.name !== 'empresas-id'"
    @click="conversion()"
    target="_blank"
  >
    <div class="fixed right-0 z-50 bottom-0 mb-20 mr-10">
      <div
        class="w-20 h-20 rounded-full flex justify-center items-center bg-[#03D342]"
      >
        <img
          src="@/assets/img/home/help/whatsapp.svg"
          alt="Icone do Whats App, clique para enviar mensagens via Whats App"
        />
      </div>
    </div>
  </a>
</template>
<script>
export default {
  methods: {
    conversion() {
      if (process.env.VUE_APP_ENV) {
        window.gtag("event", "conversion", {
          send_to: "AW-11020125432/hwoPCKLHyooYEPiJ54Yp",
        });
      }
    },
  },
};
</script>
